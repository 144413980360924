.App {
  text-align: center;
  position: relative;
  overflow-x: hidden;
  min-height: 100vh; 
  color: var(--text-color);
  background-color: var(--background-color);
  z-index: 1;
  transition: background-color 1s ease;
}
.custom-cursor {
  cursor: url('./assets/img/cursor.webp'), auto;
}

.bg-image{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-image: var(--bg-img-url);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size:  cover;
  opacity: var(--bg-opacity);
  pointer-events: none;
  filter:invert(var(--bg-invert));
  z-index: -1;
  filter: blur(6px);
}