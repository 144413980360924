:root {
  --background-color: #04012c;
  --text-color: #ffffff;
  --link-hover:#007aff;
  --border-color: #0284c7;
  --box-shadow-color: #007aff;
  --grey-border-color:#9ca3af99;
  --bg-opacity:0.5;
  --bg-invert:0;
  --bg-img-container:#0284c733;
  --bg-img-container-hover:#0284c766;
  --bg-button:#0284c7;
  --bg-index:#04012c;
  --bg-img-url:url("../src/assets/img/background-code.webp");
}
.light-mode{
  --background-color: #f7f7f7;
  --text-color: #000000;
  --border-color: #91919b;
  --box-shadow-color: #000000;
  --bg-opacity:0.4;
  --bg-invert:100;
  --bg-img-container:#91919b6b;
  --bg-img-container-hover:#91919b33;
  --bg-button:#91919b;
  --bg-slider-button:black;
  --bg-index:#303030;
  --bg-img-url:none;
}

* {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}
html,body {
  margin: 0px;
  padding: 0px;
  font-family: 'Rajdhani', sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

